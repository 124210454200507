@import '../../../styles/variables';

.beneficiary-row {
    align-items: center;
    justify-content: center;
}

.beneficiariesControlCapital .beneficiary-row,
.beneficiariesControlCapital .updateCapital-buttonContainer,
.beneficiariesControlCapital .capital-resume {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
}
.beneficiariesControlCapital .beneficiary-name {
    margin-top: 27px;
    width: 50%;
}

.beneficiariesControlCapital .beneficiary-capital {
    margin-top: 27px;
    width: 50%;
}
.beneficiariesControlCapital .not-total-asigned {
    margin-bottom: 10px;
    font-style: italic;
}
.beneficiariesControlCapital span {
    font-size: 14px;
    padding-bottom: 10px;
    font-family: $font-family-getlife !important;
    font-weight: 500;
}
.beneficiariesControlCapital span.name {
    margin-right: 0px;
}
.beneficiariesControlCapital span.type {
    font-style: italic;
}
.beneficiariesControlCapital .updateCapital-buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.beneficiariesControlCapital .updateCapital-buttonContainer button {
    max-width: 100%;
    width: 100%;
}

.beneficiariesControlCapital .inputGetLife-errorMessageContainer {
    padding-top: 5px;
}
.beneficiariesControlCapital span.type {
    margin-left: 5px;
}

.title-resume {
    margin-bottom: 15px;
}