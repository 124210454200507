.list-elements-progress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  position: relative;
  .progression__list__container:not(:last-child) {
    padding-bottom: 16px;
  }
}

.list-elements-progress::before {
  background-color: lightgray;
  position: absolute;
  top: 50%;
  left: 0;
  height: 5px;
  width: 100%;
  z-index: 1;
}

.progression__list__container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(10px - 2px);

    background-color: #ececfe;
    width: 4px;
    height: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: calc(10px - 2px);

    width: 4px;
    height: 100%;
  }
  &:first-child {
    &::after {
      background-color: $primary;
      animation: progress 0.5s linear normal;
    }
  }
}

.progression__list__item {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  font-weight: 700;
  font-size: 17px;
  font-family: $font-family-getlife-primary;
  color: $text;
  width: calc(100% - 40px);

  &.active {
    color: $primary;
  }
}

.progression__list__item span {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
}

.numberlist {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #ececfe;
  color: $text;
  font-weight: 700;
  font-size: 11px;
  font-family: $font-family-getlife-primary;
  position: relative;
  z-index: 1;
  align-self: flex-start;

  &.active {
    background-color: $primary;
    color: $white;
  }
}

.list-elements-progress
  .progression__list__container.active
  .numberlist::after {
  content: "";
  display: flex;
}

.list-elements-progress .progression__list__container:last-child::before,
.list-elements-progress .progression__list__container:last-child::after {
  display: none;
}

@keyframes progress {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .list-elements-progress {
    max-width: 440px;
  }
}
