.dashboard {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  background-color: var(--theme-quaternary);

  &-wrapper {
    width: 100%;
    @media (min-width: 1760px) {
      padding-right: 320px;
    }
  }

  &-allianz {
    padding: 16px 24px;
    @media (min-width: 1024px) {
      padding: 16px 48px;
    }
  }

  &-page {
    display: flex;
    flex-direction: column;
    padding: 40px 24px 48px;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    height: 100%;
  }
  &-withAllianz {
    padding-top: 16px;
    @media (min-width: 1024px) {
      padding-top: 22px;
    }
  }

  &-loading {
    height: 100%;
    .spinner-container {
      height: 100%;
    }
  }

  &-heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    justify-content: space-between;
    color: var(--dark-gray);
    @media (min-width: 1024px) {
      padding-left: 24px;
      flex-wrap: nowrap;
    }
  }

  &-headingLabel {
    flex: 1;
    @media (min-width: 1024px) {
      min-height: 80px;
    }
  }

  &-capital {
    padding: 12px 24px;
    color: var(--theme-primary);
    border: 1px solid var(--theme-primary);
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 2px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex: 1 1 100%;
    @media (min-width: 1024px) {
      width: 256px;
      flex: unset;
    }
  }

  &-capitalLabel {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-weight: 700;
    column-gap: 8px;
    & span:first-child {
      font-weight: 400;
      color: var(--dark-gray);
      white-space: nowrap;
    }
    span {
      text-align: right;
    }
    @media (min-width: 1024px) {
      flex-direction: column;
      column-gap: 0px;
    }
  }

  &-mobile {
    @media (min-width: 1024px) {
      display: none !important;
    }
  }

  &-card {
    box-shadow: 0px -1px 0px 0px rgba(66, 66, 66, 0.1) inset,
      0px 1px 3px 0px rgba(66, 66, 66, 0.1);
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    height: fit-content;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

.dashboardMenu {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 0px 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: 1024px) {
    padding: 84px 12px;
  }

  &-desktop {
    box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.1),
      0px 0px 1px 0px rgba(0, 0, 0, 0.06), 0px -1px 1px 0px rgba(0, 0, 0, 0.07);

    @media (max-width: 1023px) {
      display: none;
    }
  }

  &-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-avatar {
    padding: 24px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-weight: 500;
    color: var(--dark-gray);
    max-width: 100%;
  }

  &-online {
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: #1a9174;
    flex-shrink: 0;
  }

  &-avatarIcon {
    flex-shrink: 0;
  }

  &-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &-loadingBar {
    background-color: #eee;
    height: 20px;
    width: 120px;
    border-radius: 4px;
  }

  &-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 24px 12px;
    color: var(--dark-gray);
    font-weight: 500;
    border-radius: 8px;

    &:hover,
    &:focus-visible {
      background-color: var(--theme-quaternary);
    }
    &:disabled {
      opacity: 0.8;
    }
  }

  &-activeButton {
    background-color: var(--theme-tertiary);
    color: var(--theme-primary);
    font-weight: 700;
  }
}
