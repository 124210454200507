@import '../../../styles/variables';

.remove-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: center;
    text-align: right;
}

.remove-container > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding-left: 35px;
    padding-top: 5px;
}
.remove-container > p {
    font-family: $font-family-getlife;
    font-size: 12px;
    font-weight: 500;

    padding-left: 12px;
    cursor: pointer;
}
.remove-p {
    color: $error;
}
.remove-p:hover {
    color: $new-secondary;
}

