.accordionCustom {
  &-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #b8b6b0;
    width: 100%;
    &.active {
      border-bottom-color: var(--theme-primary);
    }
  }
  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
  }
  &-content {
    padding-top: 20px;
  }
}
