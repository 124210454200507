@import "../../../styles/variables";

.flex-getlife-no-ipa {
  display: flex;
}

.payment-newUpsellContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: $white;
  margin: auto;
  width: 100%;
  position: relative;

  &__section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-x: hidden;
    padding: 15px 25px;
    &__card__overlay {
      font-weight: 400;
      h1 {
        font-size: 28px;
        color: black;
        font-weight: 700;
        margin-bottom: 10px;
      }

      &__policyDetails {
        .grid {
          margin: 0;
        }
        .bg-inactive-gray {
          background-color: white;
        }
        div {
          width: 100%;
        }
      }

      &__sections {
        color: black;
        padding-bottom: 10px;
        margin-top: 10px;
        h2 {
          padding-bottom: 10px;
          font-size: 25px;
          font-weight: 700;
        }

        .accordion {
          li {
            display: flex;
            align-items: center;
            &::before {
              content: "\2022";
              color: $primary;
              font-weight: bold;
              font-size: 30px;
              margin-right: 10px;
            }
          }

          .bodyNonCover {
            li {
              &::before {
                color: #ff6b00;
              }
            }
          }
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        input {
          margin: 0px;
        }
      }
    }
    &--datePicker {
      background-color: white;
      padding: 40px 0px;
    }
  }

  &__buyCard {
    &--content {
      font-size: 13px;
      color: #707070;
      h2 {
        font-size: 23px;
        font-weight: 700;
        color: $primary;
        font-family: $font-family-getlife-primary;
      }
      b {
        color: $primary;
      }
    }

    &--actions {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__section-slider {
    display: flex;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    &__card__overlay {
      h1 {
        font-size: 28px;
        color: black;
      }

      &__policyDetails {
        .grid {
          margin: 0;
        }
        .bg-inactive-gray {
          background-color: white;
        }
        div {
          width: 100%;
        }
      }

      &__sections {
        color: black;
        padding-bottom: 10px;
        h2 {
          padding-bottom: 10px;
          font-size: 25px;
          font-weight: 700;
        }

        .accordion {
          .accordionItem {
            &__header {
              color: black;
            }

            &__body {
              padding: 0 10px;
              li {
                display: flex;
                align-items: center;
                &::before {
                  content: "\2022";
                  color: $primary;
                  font-weight: bold;
                  font-size: 30px;
                  margin-right: 10px;
                }
              }
              .bodyCover {
                li {
                  &::before {
                    color: $primary;
                  }
                }
              }
              .bodyNonCover {
                li {
                  &::before {
                    color: #ff6b00;
                  }
                }
              }
            }
          }
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        input {
          margin: 0px;
        }
      }
    }
    .slider::before {
      display: none;
    }
  }
  &__benefits-modal {
    & > div {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 16px;
      padding-bottom: 16px;
      svg {
        grid-row: 1 / 3;
      }
    }
    > * + * {
      border-top: 1px solid #ececfe;
      padding-top: 12px;
    }
  }
}

.payment-newUpsellContainer-second {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: $input-bg-color;
  width: 100%;
}

.upsell-header-container-second {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: $input-bg-color;
  width: 100%;
  padding-bottom: 232px;
}

.newUpsell--section1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.newUpsell--section1 .MuiDialogContent-root {
  padding: 0 !important;
}

.newUpsellPrice--section {
  width: 100%;
}

.upsell-dialog-content {
  padding: 0 !important;
  background-color: $white;
}

.upsell-exclusions-container {
  padding: 30px;
}

.upsell-exclusions-title {
  font-size: 20px;
  font-weight: 500;
  color: $primary;
}
.upsell-exclusions-p {
  font-size: 16px;
  font-weight: 400;
  color: $black;
  margin-bottom: 5px;
}

.newupsell-dialog-close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0 !important;
}

.newupsell-preheader {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 4px;
  color: $primary;
  text-align: center;
  padding-top: 0;
  padding-bottom: 5px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.1s;
}

.newupsell-header {
  font-size: 38px;
  font-weight: 700;
  color: $primary;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 74px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.newupsell-header-second {
  font-size: 38px;
  font-weight: 700;
  color: $primary;
  text-align: center;
  padding-top: 20px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}

.upsell-illustration-container {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 70px;
  animation-name: fadeInUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1.8s;
}

.upsell-illustration {
  width: 350px;
}

.upsell-personalization-handler {
  font-size: 12px;
  font-weight: 500;
  color: $primary;
  text-align: center;
}

#upsell-personalization-handler {
  animation-name: fadeInUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1.5s;
}

.upsell-personalization-handler a {
  color: $upsell-color !important;
  cursor: pointer;
  text-decoration: underline;
}

.newUpsell--holding {
  width: calc(100% - 5rem);
  display: flex;
  max-width: 990px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
}

.newUpsell--holding__img {
  height: 263px;
}

.newUpsell--holding h4 {
  /* width: 25rem; */
  font-size: 2.5rem !important;
  font-weight: bold !important;
  color: $primary;
  padding-left: 2.5rem;
}

.newUpsell--holding .newUpsell--holding__Header {
  display: none;
}

.newUpsell--holding .newUpsell--holding__HeaderLineBreak {
  display: block;
}

.upsell-illustration-container-second {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 10px;
}

.upsell-illustration-second {
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.9s;
  width: 150px;
}

.upsell-personalization-container {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.upsell-personalization-footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.upsell-personalization-claim-trust {
  color: $black;
  font-size: 10px;
  padding-top: 10px;
  font-weight: 500;
}

.upsell-personalization-img-trust {
  width: 214px;
  padding-top: 60px;
}

.upsell-personalization-img-secure {
  width: 144px;
  padding-top: 60px;
}

.upsell-personalization-claim-legal {
  color: $border-hover;
  font-size: 10px;
  font-weight: 500;
  padding-top: 40px;
  text-align: center;
  width: 50%;
  padding-bottom: 140px;
}

.upsell-cta-container {
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
  padding-top: 10px;
  display: flex;
  width: 99%;
  justify-content: flex-end;
  height: 0;
  z-index: 600;
}

.upsell-cta {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  height: 48px;
  background-color: $primary;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
  z-index: 600;
}

.upsell-cta:hover {
  background-color: $primary-hover;
  transition: 0.3s;
}

.upsell-cta p {
  font-size: 14px;
  color: $white;
  font-weight: 700;
  padding-left: 10px;
}

.upsell-compare-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: calc(100% - 140px);
  margin-top: -170px;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1.2s;
}

.upsell-resume-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: $white;
  min-height: 100px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.upsell-compare-block-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $white;
}

.upsell-fold-container .upsell-compare-block-container {
  border-radius: 8px 8px 0 0;
}

.upsell-compare-img {
  padding-top: 40px;
  padding-bottom: 40px;
  width: 30%;
  animation-name: fadeInUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}

.upsell-resume-title {
  font-size: 12px;
  color: $border-hover;
  line-height: 24px;
  font-weight: 400;
}

.upsell-resume-value {
  font-size: 16px;
  color: $primary;
  font-weight: 700;
  line-height: 24px;
}

.upsell--compare__TitleBox {
  margin: 0 2.5rem 1.75rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 885px;
}

.upsell-dialog-content .upsell--compare__TitleBox {
  max-width: 885px;
}

.upsell--compare__TitleBoxImg {
  grid-column: 2/3;
  grid-row: 1/3;
  height: 18.75rem;
}

.upsell-dialog-content .upsell--compare__TitleBoxImg {
  position: relative;
  left: 32px;
  max-height: 18rem;
  top: 20px;
}

@media (max-width: 768px) {
  .upsell-dialog-content .upsell--compare__TitleBoxImg {
    display: none;
  }

  .payment-newUpsellContainer__section--datePicker > div {
    padding-top: 1rem;
  }

  .payment-newUpsellContainer__section--datePicker
    div.flex.flex-col.justify-between.border-l {
    padding-top: 15px;
    border: 0 !important;
  }
}

.upsell-fold-container .upsell--compare__TitleBoxImg {
  transform: translate(0, -3rem);
  height: 23rem;
}

.upsell-compare-title {
  font-size: 28px;
  font-weight: 700;
  color: $primary;
  text-align: left;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  /* height: 35px; */
  padding-top: 94px;
  padding-bottom: 10px;
}

.upsell-compare-subtitle {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: $primary;
  width: 90%;
  height: 170px;
}

#upsell-navbar-container {
  animation-name: fadeInUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

.upsell-navbar-container {
  position: fixed;
  transition: 0.3s;
  opacity: 1;
  bottom: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  /* height: 120px; */
  z-index: 5;
}

.upsell-navbar {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: calc(100% - 40px);
  /* height: 88px; */
  background-color: $white;
  box-shadow: 0 0 20px 0 $shadow;
  border-radius: 8px;
  padding: 8px;
}

.upsell-navbar-inner {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: calc(100% - 40px);
}

.upsell-navbar-block-initial {
  flex-basis: 32%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.upsell-navbar-block-final {
  flex-basis: 32%;
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: center;
  justify-content: flex-end;
}

.upsell-navbar-block-vertical {
  flex-basis: 32%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.upsell-navbar-cancel1 {
  display: inline;
  font-size: 10px;
  color: $border-hover;
  padding-right: 10px;
  text-align: right;
}

.upsell-navbar-cancel2 {
  display: none;
  font-size: 10px;
  color: $border-hover;
}

.upsell-navbar-trust {
  opacity: 0.55;
}

.upsell-navbar-trusttext {
  font-size: 8px;
  line-height: 16px;
  color: $black;
  font-weight: 500;
}

.upsell-navbar-offer {
  font-size: 16px;
  color: $primary;
  font-weight: 700;
  padding-right: 10px;
}

.upsell-navbar-price {
  font-size: 22px;
  color: $primary;
  font-weight: 700;
  padding-right: 10px;
  padding-left: 10px;
  background-color: $white;
  height: 48px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 5px;
}

.upsell-navbar-button {
  font-family: $font-family-getlife;
  font-size: 16px;
  color: $white;
  font-weight: 700;
  padding-right: 20px;
  padding-left: 20px;
  background-color: $primary;
  height: 48px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 10px 20px 0 $shadow;
  cursor: pointer;
}

.upsell-navbar-button:hover {
  transition: 0.3s;
  background-color: $primary-hover;
}

.upsell-compare-container hr {
  color: $input-bg-color;
  border: 1px solid $input-bg-color;
  margin-top: 20px;
  margin-bottom: 60px;
  width: 100%;
}

.upsell-compare-toogle-container {
  display: none !important;
  flex-direction: row;
  flex-grow: 0;
  border-radius: 8px;
  background-color: $input-bg-color;
  align-content: center;
  align-items: center;
  padding: 12px 24px;
  margin-top: 40px;
  width: max-content;
}

/* .upsell-compare-coverage-block {
    padding-top: 40px;
    padding-bottom: 60px;
} */

#coverage-negative {
  display: none;
}

.upsell-compare-coverages-container {
  display: flex;
  flex-direction: row;
  /* padding-right: 60px;
    padding-left: 60px; */
}

.upsell-lecture-img {
  width: 100%;
  margin-top: -50px;
}

.upsell-fold-container {
  width: calc(100% - 5rem);
  max-width: 990px;
  /* width: 58rem; */
  animation-name: fadeInUp;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1.8s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upsell-lecture-container {
  box-sizing: border-box;
  background-color: $white;
  /* display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-start;
    justify-content: center; */
  width: 100%;
  height: fit-content;
}

.upsell-lecture-block {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-color: $white;
  border-radius: 8px;
  width: 100%;
}

.upsell-lecture-block-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 60px);
  padding-top: 30px;
}

/* .upsell-lecture-block-content {
    padding: 40px;
} */

.upsell--lecture___TitleBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.upsell-lecture-block-first {
  margin-right: 2px;
}

.upsell-lecture-block-last {
  margin-left: 2px;
}

.upsell-lecture-block-trust {
  width: 170px;
  margin-bottom: 15px;
}

.upsell-lecture-block-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: $primary;
  padding-bottom: 20px;
}

.upsell-lecture-block-subtitle {
  font-size: 1.125rem;
  font-weight: 700;
  color: $primary;
  padding-bottom: 1rem;
}

.upsell-lecture-block-avatar {
  margin-bottom: 15px;
}

.upsell-lecture-block-text {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: $primary;
  padding-bottom: 1rem;
  width: 50%;
}

.upsell-lecture-block-text-mobile {
  display: none;
}

.upsell-lecture-block-signature {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: $primary;
}

.upsell--lecture__buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 30px;
  width: calc(100% - 60px);
}

.upsell-lecture-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-family: $font-family-getlife;
  height: 48px;
  color: $primary !important;
  width: fit-content;
  border-radius: 8px;
  border: 0;
  background-color: $primary-shadow;
  cursor: pointer;
  margin-right: 1.25rem;
  font-size: 16px;
  font-weight: 700;
  padding-right: 20px;
  padding-left: 20px;
  box-shadow: 0 10px 20px 0 $shadow;
  text-decoration: none;
  transition: 0.3s;
}

.button2 {
  background-color: $primary;
  color: $white !important;
}

.upsell-lecture-button:hover {
  background-color: $primary-shadow;
}

.upsell-lecture-button.button2:hover {
  background-color: $primary-hover;
}

.hide {
  opacity: 0;
  display: none !important;
}

.upsell-not-sure {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containerCTA {
  width: 100%;

  a#callTel {
    display: none;
  }
}

.inputCapital {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  margin: auto;
  gap: 20px;
}

.inputCapital > div {
  padding: 0;
  margin-top: 32px;
}

.inputCapital > button {
  width: 100%;
}

.modalChangeDone {
  color: #424242;
  .checkedIcon {
    width: fit-content;
    background-color: #ececfe;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-bottom: 16px;
  }
}

.payment-newUpsellContainer__section__card__overlay > h1 {
  margin-bottom: 30px;
}

.payment-newUpsellContainer__newsection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .containerSection {
    max-width: 1440px;
    width: 100%;
  }
  &--tooltip {
    display: block;
    text-align: left;
  }
  &-upsellCTA {
    background-color: var(--theme-quaternary);
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .payment-newUpsellContainer__newsection {
    &-upsellCTA {
      padding-top: 0px;
    }
  }
}

@media only screen and (max-width: 920px) {
  .upsell-lecture-img-container {
    text-align: right;
  }

  .upsell-lecture-img {
    width: 50%;
  }

  .upsell-lecture-block {
    min-height: 0;
  }
  /* .upsell--lecture___TitleBox {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
    } */
  .upsell-lecture-block-first {
    margin: 0;
  }

  .upsell-lecture-block-last {
    margin: 0;
    margin-top: 10px;
  }
  .upsell-lecture-block-title {
    font-size: 18px;
  }
  .upsell-lecture-block-subtitle {
    font-size: 1rem;
  }

  .upsell-lecture-block-text-mobile {
    display: inline;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: $primary;
    padding-bottom: 1rem;
    width: 100%;
  }

  .upsell-lecture-block-text {
    display: none;
  }

  .upsell--lecture__buttons {
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: unset;
    width: calc(100% - 40px);
  }

  .upsell-lecture-button {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
  }
  .payment-newUpsellContainer__section--datePicker > div {
    padding-top: 1rem;
  }

  .payment-newUpsellContainer__section--datePicker
    div.flex.flex-col.justify-between.border-l {
    padding-top: 15px;
    border: 0 !important;
  }
}

@media only screen and (max-width: 479px) {
  .upsell-fold-container .upsell--compare__TitleBoxImg {
    /* right: 4px;
        position: absolute;
        height: 134px; */
    display: none;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .upsell-fold-container .upsell--compare__TitleBoxImg {
    /* right: 4px;
        position: absolute;
        height: 148px;
        top: -4px;*/
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 899px) {
  .upsell-fold-container .upsell--compare__TitleBoxImg {
    height: 19rem;
  }
}

@media only screen and (max-width: 768px) {
  .newupsell-read-more-container {
    display: none;
  }

  .newUpsell--section1 {
    height: auto;
  }
  .upsell-illustration {
    width: 50%;
  }

  .upsell-personalization-handler {
    width: 100%;
    text-align: center;
  }

  .upsell-cta-container {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 76px;
    background-color: $white;
    text-align: center;
    top: unset;
    box-shadow: 4px 0 20px 0 $shadow;
  }

  .upsell-cta {
    width: calc(100% - 40px);
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }

  .upsell-cta p {
    font-size: 16px;
  }

  .upsell-compare-container {
    width: calc(100% - 40px);
  }

  .upsell-resume-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .upsell-resume-block {
    padding: 5px 20px;
  }

  .upsell-compare-img {
    display: none;
    width: 80%;
  }

  .upsell--compare__TitleBox {
    margin: 0 1.25rem 2.5rem 1.25rem;
  }

  .upsell--compare__TitleBoxImg {
    height: 8.25rem;
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .upsell-compare-title {
    font-size: 18px;
    padding-top: 3rem;
  }
  .upsell-compare-subtitle {
    font-size: 14px;
    grid-column: 1/3;
    grid-row: 2/3;
    height: auto;
    padding-top: 20px;
  }

  .upsell-navbar-container {
    height: 90px;
    width: 100vw;
    box-sizing: border-box;
  }

  .upsell-navbar {
    width: 100%;
    height: 90px;
    border-radius: 0;
  }

  .upsell-navbar-block-initial {
    flex-basis: 50%;
  }

  .upsell-navbar-block-vertical {
    display: none;
  }

  .upsell-navbar-block-final {
    flex-basis: 50%;
    flex-direction: column;
    align-items: unset;
  }

  .upsell-navbar-cancel1 {
    display: none;
  }

  .upsell-navbar-cancel2 {
    display: inline;
  }

  .upsell-navbar-offer {
    display: none;
  }

  .upsell-navbar-price {
    font-size: 16px;
    background-color: $input-bg-color;
  }

  .upsell-navbar-button {
    font-size: 16px;
  }

  .upsell-compare-coverages-container {
    display: flex;
    flex-direction: column;
    /* padding-right: 20px;
        padding-left: 20px; */
  }

  .preLabel--toogle {
    font-size: 14px;
  }

  .newupsell-header {
    font-size: 26px;
    line-height: 34px;
  }

  .newUpsell--holding {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 60px 16px 0 16px;
    box-sizing: border-box;
  }

  .newUpsell--holding__img {
    height: 12.5rem;
  }

  .newUpsell--holding h4 {
    font-size: 1.25rem !important;
    font-weight: bold !important;
    padding-left: 0.5rem;
  }

  .newUpsell--holding .newUpsell--holding__Header {
    display: block;
  }

  .newUpsell--holding .newUpsell--holding__HeaderLineBreak {
    display: none;
  }

  .upsell-personalization-claim-legal {
    width: calc(100% - 40px);
  }

  .newupsell-header-second {
    font-size: 26px;
    line-height: 34px;
  }

  .upsell-fold-container {
    width: 100%;
    position: relative;
  }

  .newupsell-preheader {
    font-size: 16px;
    padding-top: 40px;
  }
}

.newUpsell-banner--section h1 {
  text-align: center !important;
}

.payment-newUpsellContainer__chips {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}
.sticky-shadow {
  align-items: center;
  gap: 8px;
}

.payment-newUpsellContainer__buyCard--price {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  color: #686868 !important;
  h2 {
    color: #686868 !important;
    font-weight: 500 !important;
    font-size: 22px !important;
  }
}

.payment-newUpsellContainer__buyCard--discounted {
  text-decoration: line-through;
}
