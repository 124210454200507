@import "../../../../../../styles/variables";
.note-pdf {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 10px;
  & canvas {
    margin: 0px !important;
    width: 100% !important;
    max-width: 1000px !important;
    height: unset !important;
    box-shadow: 0px 6px 12px 0px rgba(47, 43, 67, 0.10);
    border-top: 0.5px solid #ECECEC;
    border-radius: 8px;
  }
  & .react-pdf__Page__annotations {
    display: none;
  }
  & > div {
    margin-top: 16px;
  }
}
