@import '../../styles/variables';

.box {
    border: 2px solid $primary;
    max-width: 740px;
    border-radius: 8px 8px 5px 5px;
    background-color: $primary;
    width: 100%;
    &__title{
        border-radius: 8px 8px 0px 0px;
        color: $white;
        margin: -1px;
        padding: 15px 20px;
        font-weight: 700;
        font-size: 14px;
        font-family: $font-family-getlife-primary;
    }
    &__children {
        border-radius: 6px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        background-color: $white;
        border: 1px solid $primary;
        margin: -1px;
    }
}