@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/variables';

/* cyrillic-ext */
@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/merriweather/v28/u-440qyriQwlOrhSvowK_l5-cSZMZ-Y.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/merriweather/v28/u-440qyriQwlOrhSvowK_l5-eCZMZ-Y.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/merriweather/v28/u-440qyriQwlOrhSvowK_l5-cyZMZ-Y.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/merriweather/v28/u-440qyriQwlOrhSvowK_l5-ciZMZ-Y.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/merriweather/v28/u-440qyriQwlOrhSvowK_l5-fCZM.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

html,
body {
    margin: 0 !important;
    /* width: available !important; */
    font-family: $font-family-getlife !important;
    scroll-behavior: smooth;
}

.form-group {
    margin: 0.5em;
    display: flex;
}

#insuranceform {
    margin: 2em;
    display: flex;
    border: 1px $black solid;
    padding: 1em;
    width: 50%;
}

#insuranceform label {
    margin-right: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-link {
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    box-shadow: none;
}

.box-shadow-0 {
    box-shadow: none !important;
}

.layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    min-height: calc(100vh - 200px);
}
.layoutItLogo {
	svg {
		width: 180px;
		height: 80px;
		@media (min-width: 768px) {
			width: 204px;
			height: 80px;
		}
	}
}
.layoutItSecondaryLogo {
	svg {
		width: 66px;
		height: 80px;
		@media (min-width: 768px) {
			width: 75px;
			height: 80px;
		}
	}
}
.WLLogoPadding {
    padding: 10px 0;
}
.h-loader{
    height: calc(100vh - 200px);
}

.h-fit {
    height: fit-content !important;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/* TODO: This styles should be loaded from material ui but its not working currently. remove .ChoosePage in the future   */

@media (min-width: 1280px) {
    .ChoosePage .MuiContainer-maxWidthLg {
        max-width: 1280px;
    }
}

@media (min-width: 600px) {
    .ChoosePage .MuiContainer-root {
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
}

@media (max-width: 768px) {
    #hubspot-messages-iframe-container iframe {
        bottom: 100px !important;
    }
  }

.alice-document-section-side-container {
    margin: auto;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    fill: $primary !important;
    color: $primary !important;
}

.AllianzContainerLogo {
    height: 94px;
}
.logoAllianz {
    height: 94px;
}

@media (max-width: 768px) {
    .logoAllianz {
        height: 80px;
    }
  }

a {
    font-weight: 400; text-decoration: none;
}

a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
}