.formBuilder-container {
	width: 100%;
	padding-top: 92px;
	padding-bottom: 60px;
	max-width: 540px;

	@media only screen and (max-width: 768px) {
		padding-top: 24px;
	}
	&-it {
		padding-top: 24px;
		@media only screen and (max-width: 768px) {
			padding-top: 0px;
		}
	}
}

.formBuilder-form {
	align-items: center;
	text-align: center;

	.imagesIntentionQuestion {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 320px;
		transition: all .5s ease-in-out;

		img {
			position: absolute;
			top: 0;
			max-height: 320px;

			&.imgBackground {
				z-index: 1;
				position: relative;
			}

			&.imgMortgage {
				z-index: 2;
			}

			&.imgLoan {
				z-index: 3;
			}

			&.imgFamilyCover {
				z-index: 4;
			}

			&.imgOnlyLoan,
			&.imgOnlyFamily,
			&.imgOnlyMortgage {
				z-index: 2;
			}
		}
	}
}

.formBuilder-container .customRadioButton-container {
	padding-bottom: 40px;
}

.formBuilder-container input {
	max-width: 100%;
}

.formBuilder-container .SelectGetlife2 {
	width: 340px;
}

.formBuilder-container .mediumWidth {
	width: 100%;
}

.formBuilder-container .customRadioButton-componentContainer {
	padding: 0 !important;
}

.formBuilder-buttonContainer {
	max-width: 352px;
    width: 100%;
	input[type="button"]{
		margin: 0;
	};
	display: flex;
	justify-content: center;
}

.buttonContainer-marginTop {
	margin-top: 16px;
}

.formBuilder-buttonContainer-mobile {
    flex: 1;
	align-self: center;
}

.formBuilder-buttonContainer-mobile-primary {
	white-space: nowrap;
}

.goBackButtonWidthMobile {
	margin: unset;
}

@media only screen and (max-width: 768px) {
	.formBuilder-form {
		padding-left: 0;
	}

	.formBuilder-container .SelectGetlife2 {
		width: 100%;
	}

	.formBuilder-container .mediumWidth {
		width: 100%;
	}
}