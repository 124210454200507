@import '../../../../styles/variables';

.formBankingEntity-container {
    display: flex;
    flex-direction: column;
}

.formBankingEntity-GridContainer {
    width: 100%;
}

.formBankingEntity-line {
    width: 85%;
    height: 1px;

    background-color: $input-border-color;
}

.formBankingEntity-addContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 45px !important;
    margin-bottom: 40px !important;
}

.formPhysicalPerson-buttonContainer {
    margin-top: 52px;
}

.grid-beneficiary {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .row {
        display: flex;
        flex-direction: row;
        text-align: left;
        margin-bottom: 10px;
        div {
            width: 100%;
            max-width: 100%;
        }
        & .capital {
            margin-top: 8px !important;
        }
        p{
            padding-left: 25px;
            color: $error;
        }
    }
}
@media only screen and (max-width: 720px) {
    .formBankingEntity-GridContainer {
        padding-left: 0px;
    }
    .formPhysicalPerson-buttonContainer {
        width: 100%;
    }
}
