@import "../../../../styles/variables";

.welcomeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 740px;
  width: 100%;

  .welcomeSection__title {
    font-weight: 700;
    font-size: 34px;
    line-height: 120%;
    letter-spacing: -0.065em;
    color: $primary;
    font-family: $font-family-getlife-primary;
    text-align: left;
  }
  .welcomeSection_subtitle {
    font-weight: 400;
    font-size: 17px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: $text;
    font-family: $font-family-getlife-primary;
    text-align: left;
  }
  .welcomeSection__userInfo {
    display: flex;
    justify-content: space-between;
    background-color: var(--theme-quaternary);
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 5px;
    gap: 10px;
  }
  .welcomeSection__userInfo div {
    display: flex;
    font-weight: 400;
    font-size: 17px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #333333;
    img {
      margin-right: 8px;
    }
  }
}

@media (max-width: 768px) {
  .welcomeSection {
    .welcomeSection__title {
      margin-bottom: 8px;
    }

    .welcomeSection__userInfo {
      flex-direction: column;
    }

    .welcomeSection__userInfo div {
      font-size: 14px;
    }
  }
}
