@import "../../../../styles/variables";

.coupon {
    display: flex;
    max-width: 740px;
    align-self: center;
    width: 100%;
    @media (max-width: 768px) {
        padding: 0px 25px;
    }
    h3 {
        font-size: 22px;
        font-weight: 700;
        line-height: 27.5px;
    }
    &__body {
        width: 100%;
        &__input {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            div {
                align-items: flex-start;
                @media (max-width: 430px) {
                    padding: 0px;
                }
                .flex-row {
                    flex-direction: row;
                    align-items: center;
                }
                input {
                    max-width: 100%;
                }
            }

            &--span {
                display: flex;
                align-items: center;
                padding-top: 10px;
                color: #26313780;
                svg {
                    margin-right: 5px;
                }
            }
            &--icon {
                margin-left: -40px;
                margin-right: 15px;
                margin-top: 5px;
                cursor: pointer;
            }
            &--link {
                color: #6c6c6c;
                text-decoration-line: underline;
                padding-left: 5px;
                cursor: pointer;
            }
        }
        &__button {
            display: flex;
            justify-content: center;
            div {
                max-width: 360px;
            }
        }
    }
    &__remove {
        display: flex;
        flex-direction: row;
        gap: 4px;
        color: #7B7B7B;
        svg {
            margin-top: 4px;
        }
    }
}

.couponInfo {
    font-family: $font-family-getlife-primary;
    width: 100%;
    &__link {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        letter-spacing: -0.02em;
        text-decoration-line: underline;
        color: $primary;
    }
}

.couponAlert {
    position: sticky;
    top: 80px;
    z-index: 40;
    margin-bottom: -1px;
    align-items: center;
    .border-solid {
        padding: 0;
        margin: 0;
    }
    &__header {
        justify-content: center;
        @media (max-width: 768px) {
            justify-content: space-between;
        }
    }

    &__title {
        display: flex;
        width: auto;
        padding: 16px 16px;
        align-items: center;
        &__container {
            display: flex;
            align-items: flex-start;
            padding-left: 30px;
            @media (max-width: 768px) {
                padding-left: 20px;
                flex-direction: column;
                :nth-child(1) {
                    order: 2;
                    flex-direction: row-reverse;
                }
                :nth-child(2) {
                    order: 1;
                    flex-direction: column;
                }
            }
        }

        &__line {
            border: 1px solid #424242;
            transform: rotate(90deg);
            height: 0px;
            width: 32px;
            display: flex;
            align-self: center;
            @media (max-width: 768px) {
                display: none;
            }
        }
        &__first {
            display: flex;
            width: auto;
            align-items: center;

            &--name {
                font-weight: 700;
                font-size: 18px;
                @media (max-width: 430px) {
                    font-size: 15px;
                }
                @media (max-width: 368px) {
                    font-size: 14px;
                }
                line-height: 24px;
                color: #707070;
                padding-right: 20px;
            }
            &--validate {
                font-weight: 500;
                font-size: 18px;
                @media (max-width: 430px) {
                    font-size: 12px;
                }
                line-height: 23px;
                padding-right: 10px;
            }
        }
        &__second {
            display: flex;
            width: auto;
            align-items: center;
            padding-left: 20px;
            @media (max-width: 768px) {
                padding-left: 0px;
            }
            &--validate {
                @media (max-width: 430px) {
                    font-size: 13px;
                    line-height: 18px;
                }
                padding-right: 20px;
            }
            &--code {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                text-transform: uppercase;
                color: #707070 !important;
                letter-spacing: 0.1rem;
                @media (max-width: 768px) {
                    display: none;
                }
            }
        }

        &__icon {
            display: flex;
            align-self: center;
            padding-left: 30px;
        }

        @media (max-width: 768px) {
            padding: 16px 16px;
        }
    }

    &__body {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 50px 160px;
        @media (max-width: 768px) {
            padding: 16px 16px;
        }
    }
}

.border-validate, .border-validate-null {
    border-bottom: 4px solid #018565;
}

.border-invalidate {
    border-bottom: 4px solid #e53737;
}

.bg-validate, .bg-validate-null {
    background-color: #f5fffb;
}


.bg-invalidate {
    background-color: #fcf2f2;
}

.validate {
    color: #018565;
    background-color: #f5fffb;
}

.validate-null {
    color: #018565;
    background-color: #f5fffb;
}

.invalidate {
    color: #e53737;
    background-color: #fcf2f2;
}

.couponText {
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    color: #707070;
    font-weight: 700;
    padding-right: 20px;
}

.mobileCouponText {
    @media (max-width: 768px) {
        flex-direction: row-reverse;
    }
}

.couponTopStyle {
    top: 190px !important;
}
