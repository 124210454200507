@import '../../../styles/variables';

.circleNumber-mainContainer {
    background-color: $primary;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
}
.circleNumber-mainContainer > p {
    font-size: 0.75rem;
    color: $white;
}
.circleNumber-mainContainerDisabled {
    background-color: $text;
    border-radius: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
}

.circleNumber-mainContainerDisabled > p {
    font-size: 0.75rem;
    color: $black;
}
.circleNumber-mainContainerDisabled > img {
    max-width: 1.5rem;
}
