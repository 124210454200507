.personalData1-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	.personalData1__form{
		display: flex;
		flex-direction: column;
		row-gap: 30px;
		width: 100%;
		max-width: 100%;
		.personalData1__form__input{
			width: 100%;
			display: flex;
			justify-content: center;
			.relative {
				width: 100%;
			}
			input {
				width: 100%;
				@media only screen and (max-width: 720px){
					min-width: auto;
				}
			}
			div{
				align-items: flex-start;
				@media only screen and (max-width: 720px){
					width: 100%;
				}
			}
			& > * {
				padding: 0;
				margin: 0;
			}
			.group {
				max-width: 100%;
			}
		}
		.personalData1__form__submit{
			width: 100%;
			max-width: 360px;
			margin: 0 auto;
			display: flex;
    		justify-content: center;
			& div {
				display: flex;
				justify-content: center;
			}
			input {
				margin: 0px;
			}
		}
	}
}

@media only screen and (max-width: 720px) {
	.personalData1-container {
		padding-left: 0px;
	}
}

.personalData1__form__input input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #B8B6B0;
	opacity: 1; /* Firefox */
  }
  
  .personalData1__form__input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #B8B6B0;
  }
  
  .personalData1__form__input input::-ms-input-placeholder { /* Microsoft Edge */
	color: #B8B6B0;
  }