@import "../../../styles/variables";

.changePassword {
  background-color: $input-bg-color;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.changePassword--main {
  width: 375px !important;
  background-color: $white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border-bottom: solid 1px $input-border-color;
  padding: 25px;
}

.changePassword--main__imgElipse {
  width: fit-content;
  background-color: var(--theme-quaternary);
  align-self: center;
  border-radius: 50%;
  padding: 10px;
}

.changePassword--main__img {
  width: 160px;
  margin: 0 !important;
}

.changePassword--main__happyFace {
  width: 47px;
  margin: 25px 0 0 0;
}

.changePassword--main__title {
  font-size: 25px !important;
  line-height: 120%;
  letter-spacing: -0.06em;
  color: $primary;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: left;
  margin-top: 25px;
  padding-block: 30px;
}

.changePassword--main__subtitle {
  font-size: 17px;
  line-height: 160%;
  letter-spacing: -0.02em;
  margin-bottom: 25px;
}

.changePassword--main__back {
  color: $primary;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}

.changePassword--main__btn {
  background-color: $primary;
  width: 275px;
  height: 48px;
  font-family: $font-family-getlife;
  font-size: 16px;
  font-weight: bold;
  color: $white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 40px 0 41px 0;
}

.otpdisabled--button {
  margin-bottom: 40px;
}
