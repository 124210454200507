.BannerEnvironment {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    &.DEV {
        color: white;
        background-color: #077816;
    }
    &.TEST {
        color: white;
        background-color: #e3751b;
    }
    &.LOCAL {
        color: white;
        background-color: #7100b3;
    }
}

.resetLead {
    cursor: pointer;
}

.resetLead:hover {
    color: yellow;
}