@import "../../styles/variables";

.confirmationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 790px;

  .confirmationPage-content {
    margin-bottom: 125px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 60px;
    width: 100%;

    .dataBlockProgressInfo {
      display: flex;
      flex-direction: row;

      & > div {
        flex: 50%;
      }

      &__link {
        color: $primary;
        text-decoration: underline;
        font-weight: 700;
      }
    }

    .verticalLine {
      max-width: 44px;
      border-right: solid 1px var(--theme-primary);
    }

    .progressNextStep {
      display: flex;
      flex-direction: column;
      padding-left: 44px;
      @media (max-width: 768px) {
        padding-left: 0px;
      }
      row-gap: 50px;
      .infoNextStep {
        &--text {
          display: flex;
          align-items: center;
          padding-bottom: 50px;
          p {
            padding-left: 20px;
            flex: 1;
          }
        }
        &--button {
          display: flex;
        }
      }
    }

    .icon-check {
      padding-top: 60px;
      display: flex;
      justify-content: flex-start;
      svg {
        width: 70px;
        height: 70px;
      }
    }

    .confirmationTitle {
      font-weight: 700;
      font-size: 25px;
      line-height: 140%;
      letter-spacing: -0.02em;
      align-self: flex-start;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    .confirmationSubtitle {
      font-weight: 400;
      font-size: 17px;
      line-height: 160%;
      letter-spacing: -0.02em;
      color: #4a4a4a;
      max-width: 740px;
      overflow-wrap: break-word;
    }

    .confirmationPage-oneSign {
      width: 100%;
      max-width: unset;
      margin: 24px 0;
      @media (min-width: 768px) {
        margin-top: 26px;
        margin-bottom: 40px;
      }
    }

    .dataBlock {
      // max-width: 325px;

      hr {
        margin: 30px auto;
        border-bottom: solid 1px;
        color: $primary;
      }
    }
  }
}

.spinnerLoading {
  margin: auto;
}

@media (max-width: 768px) {
  .confirmationPage .confirmationPage-content .dataBlockProgressInfo {
    flex-direction: column;
    gap: 40px;
  }
  .confirmationPage {
    .confirmationPage-content {
      padding-top: 40px;
    }
  }
}
