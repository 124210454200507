@import '../../../../../styles/variables';

.waitingSignature-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    background-color: $input-bg-color;
}


.waitingSignature-outerContainer {
    width: 100%;
}


.waitingSignature-container .button-primary {
    transform: none !important;
}

.waitingSignature-topContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    background-color: $white;
    padding-bottom: 100px;
    /*box-shadow: 2px 8px 40px 40px rgba(0, 0, 0, 0.06);*/
}

.waitingSignature-topContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.waitingSignature-topContent p {
    text-align: center !important;
}

.waitingSignature-animation {
    width: 153px;
    height: 124px;
    padding-top: 54px;
}

.waitingSignature-congrats {
    font-family: $font-family-getlife;
    font-size: 36px;
    color: $primary;
    font-weight: 700;
    @media (min-width: 1024px) {
        font-size: 48px;
    }
}

.waitingSignature-congratsBold {
    padding-top: 18px;
    font-family: $font-family-getlife;
    font-size: 36px;
    color: $primary;
    font-weight: 700;
    @media (min-width: 1024px) {
        font-size: 48px;
    }
}

.waitingSignature-p {
    font-family: $font-family-getlife;
    font-size: 20px;
    font-weight: 500;
    max-width: 600px;

    color: $primary;
}

.waitingSignature-buttonContainer {
    padding-top: 32px;
}

.waitingSignature-signedError {
    padding-top: 15px;
    font-size: 12px;
    color: $error;
}

.waitingSignature-botomContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: calc(100% - 140px);

    margin-top: -40px;
}

.waitingSignature-boxContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    border-radius: 8px;
    box-shadow: 2px 8px 35px 15px $shadow;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 50%;
    min-height: 200px;
    background-color: $white;
    color: $primary;
}

.waitingSignature-boxContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    width: calc(100% - 100px);
    height: auto;
    font-size: 18px;
}

.waitingSignature-boxContent .inputGetLife-container .inputGetLife-input {
    color: $primary;
}

.waitingSignature-boxContent ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.waitingSignature-boxContent ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.waitingSignature-boxContent ul li label {
    padding-right: 30px;
    font-weight: 600;
}

.waitingSignature-boxContent ul li .inputGetLife-container {
    width: 100%;
    max-width: 300px;
}

.waitingSignature-box-title {
    font-weight: 700 !important;
    font-family: $font-family-getlife;
    font-size: 24px !important;
    line-height: 32px;
    color: $primary !important;
    padding: 0 0 18px 0;
    width: 100%;
    display: block;

    >p {
        display: contents;
    }
}

.waitingSignature-box-title>a {
    color: $primary !important;
}

.waitingSignature-box-button {
    margin-top: 16px;
}

.waitingSignature-box-message {
    padding-top: 18px;
    font-weight: 600;
}

.messageError {
    color: $error;
}

.waitingSignature-starBox {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    padding-top: 6px;
    width: 100%;
}

.waitingSignature-trusPilotLabel {
    font-size: 12px;
    font-weight: 500;
    padding-top: 20px;

    color: $primary;
}

.waitingSignature-TruststarContainer {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    height: 48px;
    background-color: $input-bg-color;
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    width: 50%;
    max-width: 225px;
}

.waitingSignature-points {
    font-size: 22px;
    font-weight: 600;
    color: $primary;
    padding-right: 20px;
}

.waitingSignature-starButtonContainer {
    width: calc(50%);
    padding-left: 11px;
}

.waitingSignature-starsContent {
    display: inline-flex;
    width: fit-content;
}

.waitingSignature_stars_icon_active {
    color: $alert-color;
}

.waitingSignature_stars_icon_inactive {
    color: $input-bg-color;
}

.waitingSignature-footerContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 800px;
}

.waitingSignature-footerContainer img {
    padding-top: 60px;
}

.waitingSignature-footer {
    padding-top: 60px;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: $text;
    padding-bottom: 60px;
    width: 100%;
}

.trustcolor {
    color: $upsell-color;
}

@media only screen and (max-width: 1280px) {
    .waitingSignature-boxContent .inputGetLife-container .inputGetLife-input {
        width: 200px !important;
    }
}

@media only screen and (max-width: 992px) {
    .waitingSignature-boxContent .inputGetLife-container .inputGetLife-input {
        width: auto !important;
    }

    .waitingSignature-botomContainer {
        flex-direction: column;
        width: 100%;
    }

    .waitingSignature-boxContainer {
        width: calc(100% - 40px);
        margin-bottom: 16px;
    }

    .waitingSignature-boxContent {
        width: calc(100% - 112px);
    }

    .waitingSignature-box-title {
        width: 65%;
    }

    .waitingSignature-footer {
        margin: 0;
    }

    .waitingSignature-footerContainer {
        width: calc(100% - 128px);
    }
}

@media only screen and (max-width: 720px) {
    .waitingSignature-boxContent ul {
        width: 100%;
    }

    .waitingSignature-boxContent ul li {
        flex-direction: column;
    }

    .waitingSignature-boxContent ul li label {
        width: 100%;
        padding: 0 0 10px;
        text-align: center;
    }

    .waitingSignature-boxContent ul li .inputGetLife-container {
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .waitingSignature-starBox {
        flex-direction: column;
    }

    .waitingSignature-topContent {
        width: calc(100% - 88px);
    }

    .waitingSignature-boxContent {
        width: calc(100% - 48px);
    }

    .waitingSignature-TruststarContainer {
        width: 100%;
        max-width: unset;
        padding-left: unset;
        padding-right: unset;
        margin-top: 15px;
    }

    .waitingSignature-starButtonContainer {
        width: 100%;
        padding-left: unset;
        margin-top: 15px;
    }

    .waitingSignature-buttonContainer {
        width: 100%;
    }

    .waitingSignature-topContent button {
        width: 100% !important;
    }

    .waitingSignature-box-title {
        width: 100%;
    }

    .waitingSignature-footerContainer {
        width: calc(100% - 48px);
    }

    /* MODIFICAR EL HEADER */

    .waitingSignature-outerContainer .button-secondary {
        display: none;
    }

    .waitingSignature-outerContainer .headerStepsCalculator-innerContainer {
        justify-content: center;
    }
}