.ConditionsSummary {
  border-radius: 8px;
  border: 2px solid var(--life-blue-30, #c6c5fc);
  background: var(--White, #fff);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.07),
    0px 3px 3px 0px rgba(0, 0, 0, 0.06), 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  &__heading {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr auto;
    padding: 24px 24px 16px 24px;
    place-items: center start;
    background-color: var(--theme-quaternary);
    color: var(--theme-primary);
    hyphens: auto;
  }
  &__title {
    grid-column: 1 / 3;
    display: none;
  }
  &__logo {
    display: none;
    svg {
      width: 190px;
      height: fit-content;
    }
  }
  &__discount {
    grid-column: 1 / 3;
    color: #7b7b7b;
    text-decoration: line-through;
  }
  &__anualPrice {
    grid-column: 1 / 3;
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    color: var(--theme-primary);
    .ConditionsSummary {
      &__extender {
        margin-left: auto;
        text-decoration: underline;
        text-align: right;
      }
    }
  }
  &__accordion {
    display: none;
    padding: 16px 24px;
    & > * + * {
      margin-top: 8px;
      border-top: 1px solid #c6c5fc;
    }
  }
  &__accordionExtended {
    display: block;
  }
  &__notExtendable {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 16px;
    padding: 8px 0px;
    color: #7b7b7b;
    svg {
      display: inline-block;
    }
  }
  &__extendable {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    column-gap: 16px;
    padding: 8px 0px;
    p {
      color: #7b7b7b;
    }
  }
  &__highlightedField {
    margin-top: 2px;
    color: var(--theme-primary);
  }
  &__chevron {
    outline-offset: 4px;
    &--rotate {
      transform: rotate(180deg);
    }
  }
  &__extendableList {
    grid-column: 2 / 4;
    ul {
      color: #4a4a4a;
      list-style: disc;
      padding-inline-start: 8px;
    }
  }
}

@media (min-width: 768px) {
  .ConditionsSummary {
    &__title {
      grid-column: 1 / 2;
    }
    &__title,
    &__logo {
      display: inline-block;
    }
    &__anualPrice {
      .ConditionsSummary {
        &__extender {
          display: none;
        }
      }
    }
    &__accordion {
      display: block !important;
    }
  }
}
