@import "../../../../../../styles/variables";

.checkoutPay-bodyContainer {
  padding-top: 40px;
  height: 100%;
  @media (min-width: 1024px) {
    padding-top: 92px;
  }
}

.checkoutPay-noteContainer {
  max-width: 540px;
  margin: auto;
  padding: 24px;
  padding-bottom: 0px;
}

.checkoutPay--subtitle {
  margin-top: 8px;
  margin-bottom: 16px;
  color: var(--theme-primary);
  display: flex;
  flex-direction: row;
  gap: 4px;
  p {
    flex: 1;
  }
}

.checkoutPay-messages {
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  color: #6f6f6f;
  b {
    color: #424242;
  }
}

.checkoutPay-checkboxes {
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
  }
}

.checkoutPay--infoIcon {
  margin-top: 4px;
}

.checkoutPay-requestNote {
  background-color: $white;
  border-radius: 8px;
  height: calc(100vh - 390px);
  overflow: scroll;
}

.checkoutPay-requestNoteSize {
  height: 80vh;
}

.checkoutPay-buttonContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-bottom: 100px;
  max-width: 540px;
  margin: auto;

  & .checkoutPay-innerButtonContainer {
    gap: 16px;
    margin-bottom: 16px;
    margin-top: 56px;
    flex-direction: column;
    max-width: 360px;
    padding: 0 24px;
  }
}

.checkoutPay-innerButtonContainer,
.accept-data-processing {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  align-items: center;
  width: 100%;
  max-width: 650px;
}

.button-secondary-downloadPdf {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-decoration: none;
}

.button-secondary-downloadPdf .button-secondary-content {
  text-decoration: none;
}

.checkoutPay-buttonContainer > a {
  padding-right: 15px;
}

.checkoutPay-buttonContainer > button {
  padding-left: 15px;
}

.checkoutPay-requestNote > p {
  word-break: keep-all;
}

.checkoutPay-requestNote {
  overflow-x: hidden;
}

.accept-data-processing > div {
  margin-top: 0px !important;
  border-radius: 8px;
  background-color: $input-bg-color;
  padding: 10px !important;
  margin-bottom: 25px;
}

.accept-data-processing.confirmed > div {
  background-color: $primary-shadow;
}

.accept-data-processing > div span {
  font-size: 11px !important;
}

@media only screen and (max-width: 960px) {
  .checkoutPay-requestNote {
    height: calc(100vh - 401px);
  }

  .checkoutPay-innerButtonContainer {
    flex-direction: column;
  }
}

@media only screen and (max-width: 769px) {
  .accept-data-processing {
    margin-left: 21px;
  }
}

@media only screen and (max-width: 600px) {
  .checkoutPay-requestNote {
    height: calc(100vh - 300px);
  }

  .accept-data-processing {
    width: calc(100% - 40px);
  }
}
