@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(400px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-700px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes wipe-enter {
    0% {
        transform: scale(0, 0.025);
    }
    50% {
        transform: scale(1, 0.025);
    }
}
