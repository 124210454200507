@import "./../../styles/_variables.scss";

.conditions_container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--table {
        max-width: 375px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        padding: 0 24px;
        @media (max-width: 768px) {
            padding: 0px;
        }
    }

    &--row {
        display: flex;
        padding: 8px 0;
        width: 100%;
        justify-content: space-around;
        gap: 16px;
        border-bottom: solid 1px $life-blue-10;
    }
}

.extrapadding--right {
    padding-right: 17px;
}

.extrapadding--left {
    padding-left: 17px;
}
