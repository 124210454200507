@import '../../styles/variables';

.containerModal {
    max-width: 740px;
    display: flex;
    flex-direction: column;
    
    .containerClose {
        display: flex;
        justify-content: flex-end;

        .customModal-close {
            justify-self: flex-end;
            background-color: #F5C4DC;
            border-radius: 50px;
            margin: 25px;
            fill: $primary;

            path {
                height: 12px;
                width: 12px;
            }
        }
    }

    .blockersPopUp-contentProps {
        padding: 30px 60px 40px;
    }

    fieldset {
        top: 0 !important;
        height: 60px;
    }

    input[type=button].mx-\[25px\] {
        margin-left: 0;
        margin-right: 0;
    }


}

.header--buttonCall {
    background-color: $primary-shadow !important;
    border-radius: 50px !important;
    box-shadow: none !important;
    height: 2rem !important;
    width: 2rem !important;
    min-width: 2rem !important;
}

.header--buttonCall svg {
    color: $primary;
}

.header--buttonCall span {
    margin: 0 !important;
}

.header--popover {
    /* padding: 2rem; */
    text-align: center;
}

.header--popover__section {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 15px;

    .px-25px{
        padding: 0px;
    }
    
    input {
        max-width: none !important;
    }

    .MuiFormControl-root {
        max-height: none !important;
    }

    button {
        max-width: 100% !important;
        align-self: center;
    }

    .header--popover__text {
        align-self: center;
    }
}

.header--popover__icon {
    width: 3rem !important;
    height: 3rem !important;
    color: $primary;
}

.header--popover__iconWarning {
    width: 3rem !important;
    height: 3rem !important;
    color: $warning;
}

.header--popover__title,
.header--popover__warning__title {
    font-size: 24px;
    color: black;
    font-weight: 700;
}

.header--popover__subtext,
.header--popover__warning__subtext {
    font-size: 17px;
    color: black;
}

.header--popover__text,
.header--popover__warning__text {
    font-size: 15px;
    margin-top: 0;
}

.header--popover__text a,
.header--popover__warning__text a {
    color: $primary;
    font-weight: bold;
}

.header--popover__button {
    width: 100%;
    height: 3rem;
    background-color: $primary !important;
    border-radius: 0.5rem !important;
    margin-top: 1rem !important;
    padding: 0;
}

.header--popover__buttonText {
    color: $white;
    font-size: 1rem;
}

.header--popover__button__disabled {
    background-color: $primary-disabled !important;
}

.header--popover__policy {
    display: flex;
    align-items: center;
    font-size: 0.625rem;
    margin-top: 0;
}

.header--popover__policyInput {
    margin-right: 8px;
}

.header--popover__policyInput:checked {
    background-color: $action-color;
    box-shadow: 0 0 5px $primary;
}

.header--popover__policyInput:hover {
    cursor: pointer;
    /* background-color: rgba(198, 228, 201, 0.3); */
    box-shadow: 0 0 5px $primary;
}

.header--popover__policyAnchor {
    color: $primary;
}

.header--popover__text {
    font-size: 15px;
    margin-top: 0;
}

.header--popover__text a {
    color: $primary;
    font-weight: bold;
}

.mobileStyle {
    color: $primary;
    font-weight: bold;
    text-decoration-line: underline;
}

.InputCustom .MuiInputLabel-root {
    transform: none;
    position: relative;
    display: inline-block;
    text-align: left;
    margin-bottom: 8px;
}

.InputCustom .MuiInputBase-formControl.MuiInputBase-root {
    border-radius: 0.5rem !important;
}

.InputCustom .MuiInputBase-formControl.MuiInputBase-root .MuiInputBase-input {
    height: 24px;
    border-radius: 0.5rem !important;
}

.InputCustom
    .MuiInputBase-formControl.MuiInputBase-root
    .MuiInputBase-input::-webkit-input-placeholder {
    text-overflow: ellipsis !important;
    color: $input-border-color;
    opacity: 1 !important;
}

.InputCustom
    .MuiInputBase-formControl.MuiInputBase-root
    .MuiInputBase-input:focus {
    outline: none;
}

.InputCustom fieldset legend {
    display: none;
}

@media(max-width: 786px ) {
    .blockersPopUp-contentProps {
        padding: 30px 30px 40px !important;
    }
}
