@import '../../../styles/variables';

.presPostStackAnimation-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
}
.presPostStackAnimation-animation {
    height: 467px;
}

.presPostStackAnimation-p {
    font-family: $font-family-getlife;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: $primary;
}
