@import '../../../styles/variables';

.loginPageSignin {
    background-color: $input-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    width: 100%;
    gap: 60px;
}

.loginPage {
    background-color: $input-bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    width: 100%;
    gap: 60px;

    .header--buttonCall {
        background-color: $primary-shadow !important;
        border-radius: 50px !important;
        box-shadow: none !important;
        height: 2rem !important;
        width: 2rem !important;
        min-width: 2rem !important;
    }
    
    .header--buttonCall svg {
        color: $primary;
    }
    
    .header--buttonCall span {
        margin: 0 !important;
    }

    .questionsFooter-container--container {
        padding-bottom: 20px;
        padding-top: 40px;
        background-color: $white;
        width: 100%;
        display: flex;
        justify-content: center;

        .questionsFooter-container {
            width: 70%;
        }
    }

    .changePassword--main {
        align-items: flex-start;
        height: auto;

        .changePassword--main__title {
            width: auto;
            font-family: $font-family-getlife;
            font-size: 12px;
            text-align: left;
            margin: 30px 0 15px 0;
        }

        .changePassword--main__back {
            align-self: center;
            margin: 75px 0 60px 0;
            text-decoration: none;

            img {
                margin-right: 15px;
            }
        }
    }
    &--divider {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 24px;
        span {
            width: fit-content;
            max-width: 75%;
            color: #686868;
            text-align: center;
        }
        &::before, &::after {
            content: "";
            flex: 1;
            height: 1px;
            background-color: #D9D9D9;
        }
    }
    &--googleSSO {
        margin: 0 auto;
    }
}

.loginPage--main {
    width: 396px;
    padding: 30px;
    background-color: $white  !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px 8px 0 0;
    border-bottom: solid 1px $input-border-color;
}

.loginPage--main img {
    max-width: 220px;
}

.loginPage--main__form button {
    width: 100% !important;
    margin-top: 20px !important;

}

.error-message {
    color: $alert-color;
    font-weight: 700;
    text-align: center;
    margin-top: 5px;
}

.loginPage--main--otp {
    width: 396px;
    background-color: $white  !important;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border-bottom: solid 1px $input-border-color;
    padding: 25px;

    .loginPage--main__title {
        font-size: 34px;
        line-height: 120%;
        letter-spacing: -0.060em;
        color: $primary;
        font-weight: 700;
        margin-bottom: 15px;
        text-align: left;
    }

    .loginPage--main__subtitle {
        font-size: 17px;
        line-height: 160%;
        letter-spacing: -0.02em;
        margin-bottom: 25px;

        span {
            color: $primary;
            font-weight: 700;
        }
    }

    .loginPage--main__subtitle2 {
        font-size: 14px;
        line-height: 160%;
        letter-spacing: -0.02em;
        margin-bottom: 25px;
    }

    .loginPage--main__text {
        font-size: 15px;
        line-height: 160%;
        text-align: center;
        letter-spacing: -0.02em;

        span {
            color: $primary;
            font-weight: 700;
            text-decoration: underline;
        }
    }
}

.loginPage--main__img {
    width: 120px;
    margin: 35px 0 25px 0;
}

.otp--form__input {
    width: 100%;

    // provisional fix
    input {
        max-width: 100%;

    }

    div {
        align-items: flex-start;
    }

    &>* {
        padding: 0;
        margin: 0;
    }

    .group {
        max-width: 100%;
    }
}

.loginPage--main__form{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-block: 20px;

    // provisional fix
    input {
        max-width: 100%;

    }

    div {
        align-items: flex-start;
    }

    &>* {
        padding: 0;
        margin: 0;
    }

    .group {
        max-width: 100%;
    }
}

.form--input {
    margin-bottom: 46px !important;
}

.makeStyles-button-5 {
    color: $input-bg-color  !important;
}

.MuiButton-label {
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: $input-border-color;
}

.loginPage--main__text button{
    font-weight: 700;
    color: $primary;
    text-decoration-line: underline;
    background-color: $white;
    border: none;
    box-shadow: none;
    padding: 0;
}
