@import '../../../styles/variables';

.formAdress-container {
    display: flex;
    flex-direction: column;
}

.formAdress-GridContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    .row {
        display: flex;
        flex-direction: row;
        text-align: left;
        gap: 12px;
        div {
            width: 100%;
            max-width: 100%;
        }
    }
    button {
        max-width: 100%;
        width: 100%;
    }
}


.formAdress-footer {
    padding-top: 66px;
    word-break: break-word;
    width: 865px;
}

.formAdress-footer > p {
    font-size: 0.875rem;
    color: $border-hover;
}
.formAdress-buttonContainer {
    margin: 52px auto 0px auto;
    width: 100%;
    max-width: 360px;

}

@media only screen and (max-width: 720px) {
    .formAdress-buttonContainer {
        width: 100%;
        padding: 0px 20px 0px 20px;
        margin: 0px;
        margin-top: 52px ;
    }

    .formAdress-GridContainer {
        padding-left: 0px;
        .row {
            display: flex;
            flex-direction: column;
            text-align: left;
        }
        button {
            max-width: 100%;
            width: 100%;
        }
    }
}
