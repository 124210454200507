.waitingValidation {
  &-section {
    padding: 40px 24px;
    max-width: 544px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) {
      padding: 72px 0px;
    }
  }
  &-illustration {
    margin-bottom: 8px;
  }
  &-title {
    color: var(--theme-primary);
    margin-bottom: 24px;
    text-align: center;
    font-size: 36px;
    @media (min-width: 768px) {
      font-size: 48px;
    }
  }
  &-body {
    color: #424242;
    margin-bottom: 48px;
    text-align: center;
    font-size: 20px;
  }
  &-buttons {
    max-width: 352px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &-button {
    width: 100%;
  }
  &-faqs {
    margin-top: 48px;
    width: 100%;
    border-top: 1px solid #b8b6b0;
  }
  &-faqsText {
    u {
      text-decoration: none;
      font-weight: 700;
    }
    p,
    ul,
    a {
      color: #4a4a4a;
    }
    ul {
      list-style: disc;
      padding-inline-start: 24px;
    }
  }
  &-buttonHelp {
    margin-top: 20px;
  }
}
