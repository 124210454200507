.QuotePage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white-100, #fff);
  width: 100%;
  &__loadingCoverages {
    height: 145px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &__seriousIllnesses {
    padding: 40px 0px;
    width: 100%;
    max-width: 1115px;
    h3 {
      font-weight: 700;
      color: var(--theme-primary);
      text-align: center;
    }
  }
  &__seriousIllnessesList {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 40px;
    row-gap: 12px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  &__seriousIllnessesElement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #686868;
    font-weight: 700;
  }
  &__journeyquestion {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  &__cancelanytime {
    width: 100%;
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    @media only screen and (max-width: 768px) {
      display: flex;
      padding: 24px 25px 16px 25px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &--slider {
      display: flex;
      align-items: end;
      width: 100%;
      max-width: 1120px;
      height: 110px;
      @media only screen and (max-width: 768px) {
        display: flex;
        align-items: center;
      }
    }

    &--boxMaxCapital {
      max-width: 630px;
    }

    &--products {
      display: flex;
      width: 100%;
      max-width: 1115px;
      padding-bottom: 0px;
      align-items: flex-start;
      gap: 20px;
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    &--downloadFiles {
      display: flex;
      flex-direction: column;
      max-width: 1115px;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 40px 50px 40px 0px;
      gap: 10px;
      flex: 1 0 0;
      @media only screen and (max-width: 768px) {
        display: flex;
        padding: 15px 0px;
        gap: 3px;
        align-self: stretch;
        align-items: flex-start;
      }
    }
  }

  &__journeyquestion--cta {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    &:first-child {
      padding: 80px 160px;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    padding: 32px 0px 48px 0px;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    @media only screen and (max-width: 768px) {
      padding: 32px 24px;
    }

    &--primary {
      width: 100%;
      max-width: 352px;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    &--secondary {
      width: 100%;
      max-width: 352px;
    }

    &--sticky {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: white;
      gap: 24px;
      position: sticky;
      bottom: 0;
      margin: auto;
      z-index: 29;
      padding: 12px 16px;
      transition: opacity 0.2s;
    }
  }
}

.crossedPriceText {
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.36px;
  text-decoration-line: line-through;
  color: #7b7b7b;
  margin-bottom: 16px;
}

.priceTextStyle {
  color: var(--theme-primary);
  font-family: DM Sans;
  font-size: 80px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -1.6px;
  @media (max-width: 768px) {
    font-size: 60px;
    letter-spacing: -1.2px;
  }
}

.subtextPriceStyle {
  margin-top: 12px;
}

.textFromStyle {
  color: var(--dark-gray-70, #7b7b7b);
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.36px;
  @media (max-width: 768px) {
    font-size: 15px;
    letter-spacing: -0.3px;
  }
}

.currencyStyle {
  color: var(--theme-primary);
  font-family: DM Sans;
  font-size: 35px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.7px;
}
