.partnerPage {
  & .organic,
  & .comparison,
  & .faqs {
    width: 100%;
    max-width: 1168px;
    margin: 0 auto;
  }
}

.partnerPage {
  width: 100%;
  .organic {
    display: grid;
    gap: 24px;
    padding: 32px 24px 40px;
    &__header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      text-align: center;
      color: var(--theme-primary);
      max-width: 740px;
      margin: auto;
      svg {
        display: unset;
        margin-left: 8px;
        width: 109px;
        height: fit-content;
      }
    }
    &__form {
      height: fit-content;
      padding: 24px 16px 32px;
      background-color: var(--theme-quaternary);
      border-radius: 8px;
      box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.07),
        0px 1px 18px 0px rgba(0, 0, 0, 0.06), 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
      & > * {
        margin-bottom: 24px;
      }
      & > *:first-child {
        margin-bottom: 32px;
      }
      & > *:last-child {
        margin-top: 32px;
        margin-bottom: 0px;
      }
      .checkboxAtom {
        padding: 0;
      }
    }
  }
  .comparison {
    display: grid;
    padding: 50px 24px;
    gap: 82px;
    &__table {
      hyphens: auto;
      thead {
        hyphens: initial;
      }
      h2 {
        font-size: 22px;
      }
      td:not(:first-child) {
        min-width: unset;
        text-align: center;
        svg {
          display: inline-block;
        }
      }
      table {
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.07),
          0px 3px 3px 0px rgba(0, 0, 0, 0.06),
          0px 1px 8px 0px rgba(0, 0, 0, 0.1);
      }
    }
    &__desktopLogo {
      display: inline-block;
      text-align: center;
      padding-top: 8px;
      svg {
        width: 60px;
        height: fit-content;
      }
    }
    &__tableHeadingTitle {
      color: #6f6f6f;
      text-align: left;
    }
    &__titleColumn {
      color: #6f6f6f;
    }
    &__oddColumn {
      background-color: var(--theme-tertiary);
    }
    &__CTA {
      display: flex;
      flex-direction: column;
      gap: 18px;
      text-align: center;
      color: #424242;
      h2 {
        color: var(--theme-primary);
      }
      button {
        width: fit-content;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
  .faqs {
    padding: 60px 24px 48px;
    &__title {
      padding-bottom: 40px;
      border-bottom: 1px solid #b8b6b0;
      color: var(--theme-primary);
    }
    .accordionCustom-title {
      color: #272727;
    }
    .accordionCustom-content {
      color: #4a4a4a;
    }
  }
}

@media (min-width: 768px) {
  .partnerPage {
    .organic {
      grid-template-columns: repeat(12, 1fr);
      row-gap: 72px;
      column-gap: 32px;
      padding: 50px 24px 72px;
      &__header {
        grid-column: 1 / 13;
        svg {
          width: 146px;
        }
      }
      &__information {
        grid-column: 1 / 7;
      }
      &__form {
        padding: 24px 24px 32px;
        grid-column: 7 / 13;
      }
    }
    .comparison {
      padding: 40px 24px 48px;
      &__desktopLogo {
        svg {
          width: 140px;
        }
      }
      &__mobileLogo {
        display: none;
      }
    }
    .faqs {
      padding: 90px 24px 70px;
    }
  }
}

@media (min-width: 1024px) {
  .partnerPage {
    .organic {
      &__information {
        grid-column: 2 / 7;
      }
      &__form {
        grid-column: 8 / 13;
      }
    }
    .comparison {
      grid-template-columns: repeat(12, 1fr);
      &__table {
        grid-column: 1 / 8;
        h2 {
          text-align: center;
        }
      }
      &__CTA {
        grid-column: 8 / 13;
        justify-content: center;
        border-radius: 8px;
        text-align: left;
        background-color: var(--theme-quaternary);
        padding: 0 56px;
      }
    }
  }
}
