@import '../../../styles/variables';

.NotFound {
    width: 100%;
}

@media all and (min-width: 720px) {
    .header--nav {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .header--nav-btn {
        grid-column: 3/4;
        grid-row: 1/2;
        text-align: right;
    }
    .header--nav__button {
        margin-right: 1rem;
    }
    .header--nav-link {
        grid-column: 2/3;
        grid-row: 1/2;
        text-align: center;
    }
    .header--nav__logo {
        height: 1.7rem;
    }
}

@media all and (min-width: 1920px) {
    .header--nav__button {
        margin-right: 1.5rem;
        padding: 0.9rem;
    }
}

.main {
    background-color: $input-bg-color;
    background-image: url('../../../assets/img/getlife-elements/ilustration.png');
    background-position: center bottom;
    background-size: 900px 50%;
    background-repeat: no-repeat;
    font-family: $font-family-getlife;
    color: $primary;
    width: 100%;
}

.main--title {
    font-weight: 800 !important;
    font-size: 2.5rem !important;
    padding: 6rem 0 4rem 2rem;
    margin: 0;
}

.main--news {
    font-size: 0.8rem;
    padding: 0 0 3rem 2rem;
}

.main--subtitle {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: 0 0 0 2rem;
}

.main--text {
    font-size: 0.8rem;
    line-height: 1.5rem;
    padding: 0 0 2rem 2rem;
}

.main--text__link {
    text-decoration: underline;
    color: $primary !important;
}

.main--display {
    display: flex;
    padding: 0 0 0 2rem;
}

.main--imgArrow {
    height: 0.8rem;
    width: 0.8rem;
    line-height: 2rem;
}

.main--nav {
    list-style: none;
    padding: 0 0 20rem 1rem;
    margin: 0;
}

.main--nav__link {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 2rem;
    text-decoration: none;
    color: $primary !important;
    cursor: pointer;
}

@media all and (min-width: 720px) {
    .main {
        background-size: 100% 40%;
    }
    .main--title {
        padding: 4rem 0 2rem 5rem;
    }
    .main--news {
        font-size: 1rem;
        padding: 0 0 3rem 5rem;
    }
    .main--subtitle {
        padding: 0 0 0 5rem;
    }
    .main--text {
        padding: 0 0 2rem 5rem;
    }
    .main--display {
        padding: 0 0 0 5rem;
    }
    .fa-arrow-left {
        line-height: 1rem;
    }
    .main--nav {
        padding: 0 0 17rem 1rem;
    }
    .main--nav__link {
        line-height: 1.5rem;
    }
}

@media all and (min-width: 1200px) {
    .main {
        background-position: 247% bottom;
        background-size: 80%;
    }
    .main--title {
        font-size: 3.2rem !important;
        padding: 9rem 0 1.5rem 5rem;
    }
    .main--news {
        font-size: 1.2rem;
        padding: 0 0 1.5rem 5rem;
    }
    .main--text {
        padding: 0 0 1rem 5rem;
    }
    .main--display {
        padding: 2.5rem 0 0 5rem;
    }
}

@media all and (min-width: 1920px) {
    .main {
        background-position: right bottom;
        background-size: 70%;
    }
    .main--title {
        font-size: 4rem !important;
        padding: 18rem 0 1.5rem 5rem;
    }
    .main--news {
        font-size: 1.5rem;
        padding: 0 0 2rem 5rem;
    }
    .main--subtitle {
        font-size: 1rem;
        line-height: 1.7rem;
        padding: 0 0 0 5rem;
    }
    .main--text {
        font-size: 1rem;
        line-height: 1.7rem;
        padding: 0 0 2rem 5rem;
    }
    .main--display {
        padding: 4rem 0 0 5rem;
    }
    .fa-arrow-left {
        line-height: 2rem;
    }
    .main--nav {
        padding: 0 0 12rem 1rem;
    }
    .main--nav__link {
        font-size: 1rem;
        line-height: 2rem;
    }
}
