@import "../../../../styles/variables";

.paymentChallenger {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title {
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: center;
    h1 {
      font-size: 32px;
      @media (max-width: 768px) {
        font-size: 22px;
        padding-bottom: 0px;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--theme-quaternary);
    padding: 48px 40px;
    width: 100%;

    @media (max-width: 768px) {
      padding: 10px 0px;
      background-color: $white;
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1120px;
      gap: 25px;
      width: 100%;
    }

    &__sections {
      display: flex;
      width: 100%;
      gap: 3px;
      margin-top: 25px;
      height: fit-content;
      max-width: 1120px;
      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
      &--paymentMethod {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 50px;
        background: #ffffff;
        width: 60%;
        gap: 20px;
        .line {
          width: 100%;
          height: 0px;
          border: 1px solid $primary;
        }
        @media (max-width: 768px) {
          width: 100%;
          padding: 20px 25px 30px;
        }

        .Tabs {
          width: 100%;
          ul {
            justify-content: center;
            color: $primary;
            font-size: 16px;
            font-weight: 700;
          }
        }

        &--backButton {
          width: 100%;
          display: flex;
          padding: 15px 0px;
          margin-top: 40px;
        }
        &--final {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-bottom: 25px;
          gap: 20px;
          max-width: 325px;
          text-align: center;
          hr {
            width: 0px;
            height: 80px;
            border: 1px solid $primary;
            background-color: $primary;
            text-align: center;
            @media (max-width: 768px) {
              padding: 24px;
            }
            &--line {
              width: 0px;
              height: 80px;
              border: 1px solid $primary;
            }
          }
        }
      }

      &--resumePrice {
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 20px;
        width: 40%;
        height: fit-content;
        gap: 20px;
        @media (max-width: 768px) {
          width: 100%;
          position: static;
        }

        .coupon {
          border-top: solid 1px #e5e5e5;
          @media (max-width: 768px) {
            padding: 0px;
          }
          h3 {
            font-size: 18px;
          }
        }

        .coupon__body__input {
          padding-left: 5px;
          gap: 8px;
          .px-25px {
            padding: 0px;
          }
        }

        hr {
          width: 100%;
          height: 1px;
          background: #e2e2e2;
        }

        &--price {
          width: 100%;
          display: flex;
          flex-direction: column;
          &--conditions {
            display: flex;
            align-self: end;
          }
          &--details {
            display: grid;
            grid-template-columns: 2fr 1fr;
            margin-bottom: 24px;
            color: #7b7b7b;
            .monthlyPrice {
              margin-left: auto;
              color: #424242;
            }
          }
        }

        &--final {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 20px;
          gap: 10px;
        }
      }

      &--promoted {
        display: flex;
        gap: 8px;
      }
    }
  }
  &__final {
    background-color: $white;
    &--CTA {
      padding: 0px;
      display: flex;
      justify-content: center;
      &--Inside {
        padding: 48px;
      }

      @media (max-width: 768px) {
        padding: 50px 25px;
        background-color: var(--theme-quaternary) !important;
      }
    }
  }
}

.PaymentCardContent__stripe {
  padding-top: 50px;
  display: flex;
  font-size: 8px;
  gap: 5px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    padding-top: 20px;
    justify-content: center;
  }
}

.checkoutConfirmation-buttonContainer {
  max-width: 360px;
  margin: 0 auto;
}

.paymentChallenger__body__sections--paymentMethod--backbutton {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}

.checkoutConfirmation-payCheckbox div div {
  padding-right: 0;
  padding-left: 0;
}

.coupon__body__button {
  button {
    width: 100%;
  }
}

.coupon__body__conditions {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
