@import "../../styles/variables";
@import "./components/Progression/Progression.styles.scss";

.backGround-white {
    background-color: $white;
}

.decisionPage {
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-content: center;
    min-height: 100vh;
    padding-bottom: 2rem;
    margin: 0;
    .decisionPage-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;
        background-color: $white;

        &__radiobutton {
            padding: 20px 0 30px 0;
        }

        &__accordion {
            max-width: 740px;
            display: flex;
            align-self: center;
            width: 100%;
            padding: 20px 20px 30px 20px;
            flex-direction: column;
        }

        .grid {
            margin: 0;
        }
    }
}

.progression-container,
.welcome-container {
    display: flex;
    align-self: center;
    max-width: 740px;
    width: 91.6667%;
    margin: 50px 0px;
}

.continue-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 740px;
    width: 91.6667%;
    padding: 50px 0px;
    align-items: center;
    gap: 10px;
}

.continue-container > div {
    justify-content: center;
    padding: 10px 0px;
}

.text-continue {
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    font-family: $font-family-getlife-primary;
}

.text-continue a {
    font-size: 15px;
    font-family: $font-family-getlife-primary;
    color: $primary;
    font-weight: 700;
}

@media (max-width: 768px) {
    .progression-container,
    .welcome-container {
        margin: 23px 0px;
    }
}
