.legalPages {
	&--layout {
		max-width: 1184px;
		width: 100%;
		padding: 40px 16px;
		@media (min-width: 768px) {
			padding: 64px 24px;
		}
	}
	&--title {
		color: #1943a0;
		margin-bottom: 40px;
	}
	&--sectionHeading {
		color: #1943a0;
		margin-bottom: 24px;
	}
	&--section {
		padding: 16px 0px;
	}
	ul {
		padding-inline-start: 16px;
		li {
			padding-left: 16px;
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
		::marker {
			content: "•";
		}
	}
	ol {
		list-style: none;
		counter-reset: item;
		padding-inline-start: 16px;
		li {
			counter-increment: item;
			padding-left: 16px;
		}
		::marker {
			content: counter(item) ".";
		}
	}
	section {
		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}
	a {
		font-weight: 600;
		text-decoration: underline;
	}
	table {
		width: 100%;
		max-width: 100%;
		th {
			background-color: #1943a0;
			color: #ffffff;
			text-align: left;
		}
		th,
		td {
			padding: 12px 16px;
			vertical-align: top;
		}
	}
	table,
	th,
	td {
		border: 1px solid #ececec;
		border-collapse: collapse;
	}
	&--tableFifty {
		word-break: break-all;
		@media (min-width: 768px) {
			word-break: unset;
		}
		& th,
		& td {
			width: 50%;
			max-width: 50%;
		}
	}
	&--tableTwentyFive {
		word-break: break-all;
		@media (min-width: 768px) {
			word-break: unset;
		}
		& th,
		& td {
			width: 25%;
			max-width: 25%;
		}
	}
}
