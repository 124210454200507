@import '../../../../styles/variables';

.formPhysicalPerson-container {
    display: flex;
    flex-direction: column;
}

.formPhysicalPerson-GridContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.formPhysicalPerson-line {
    height: 1px;
    width: 85%;
    background-color: $input-border-color;
}

.formPhysicalPerson-addContainer {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 45px !important;
    margin-bottom: 40px !important;
}

.formPhysicalPerson-buttonContainer {
    margin-top: 52px;
}

.formPhysicalPerson-buttonContainer button {
    max-width: 100%;
    width: 100%;
}

.grid-beneficiary {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .row {
        display: flex;
        flex-direction: row;
        text-align: left;
        margin-bottom: 10px;
        gap: 16px;
        div {
            width: 100%;
            max-width: 100%;
        }
        & .capital {
            margin-top: 10px;
        }
        p{
            padding-left: 25px;
            color: $error;
        }
    }
}

@media only screen and (max-width: 720px) {
    .formPhysicalPerson-GridContainer {
        padding-left: 0px;
        .grid-beneficiary {
            .row {
                display: flex;
                flex-direction: column;
                text-align: left;
            }
        }
    }

    
}
