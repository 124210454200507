@import '../../../styles/variables';

.add-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    justify-content: center;
    text-align: right;
}

.add-container > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding-left: 35px;
    padding-top: 5px;
}

.add-container > p {
    font-family: $font-family-getlife;
    font-size: 12px;
    font-weight: 500;
    padding-left: 12px;
    cursor: pointer;
}

.add-container:hover {
    opacity: 0.8;
}