@import '../../../styles/variables';

.inputGetLife-container {
    display: flex;
    flex-direction: column;
    /* padding-top: 26px; */
    /* padding-left: 76px; */
}
.inputGetLife-container > label,
.inputGetLife-container > div > label {
    font-size: 14px;
    padding-bottom: 10px;
    font-family: $font-family-getlife !important;
    font-weight: 500;
}

.inputGetlife-tooltip {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: left;
    padding-top: 0px;
}
.offers_box_info {
    font-size: 14px !important;
    color: $primary-shadow;
    padding: 0px 0px 10px 5px;
}
.inputGetLife-container > input::placeholder {
    color: $placeholder;
}

.inputGetLife-container > input:focus {
    border: solid 2px $border-hover !important;
    outline: none;
    box-shadow: 1px 0px 1px 2px $shadow;
}

.inputGetLife-input:hover {
    border: solid 2px $border-hover;
}
.inputGetLife-input {
    font-family: $font-family-getlife !important;
    font-weight: 400;
    height: 48px;
    border-radius: 8px;
    border: solid 2px $input-border-color;
    font-size: 1rem;
    padding-left: 10px;
    transition: border 300ms;
    transition: box-shadow 300ms;
    /* width: 340px; */
}

.inputGetLife-errorMessageContainer {
    padding-top: 10px;
}

.inputGetLife-input-error {
    font-family: $font-family-getlife !important;
    font-weight: 400;
    height: 48px;
    border-radius: 8px;
    border: solid 2px $new-secondary;
    font-size: 1rem;
    padding-left: 10px;
    transition: border 300ms;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	height: auto;
}
input[type='number'] {
	-webkit-appearance: none;
}

@media only screen and (max-width: 720px) {
    .inputGetLife-input,
    .inputGetLife-input-error {
        width: auto !important;
    }
}
