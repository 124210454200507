@import "../../styles/variables";

.telesubscription {
    display: flex;
    width: 100%;
    max-width: 540px;
    padding: 72px 0px 80px 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    @media only screen and (max-width: 768px) {
        padding: 40px 24px;
        gap: 24px;
    }

    &__section {
        &__second {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 48px;
            align-self: stretch;

            @media only screen and (max-width: 768px) {
                gap: 24px;
            }

            &--text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                align-self: stretch;

                &--title {
                    text-align: center;
                    align-self: center;
                }

                @media only screen and (max-width: 768px) {
                    gap: 8px;
                }
            }

            &--buttons {
                display: flex;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;
                @media only screen and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}
