.title {
  color: #424242;
  height: fit-content;
  margin: auto 0;
}

.benefits {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * + * {
    border-top: 1px solid #ececfe;
  }
  .benefit {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0px;
    & > * + * {
      padding-left: 16px;
    }
    .title {
      color: var(--theme-primary);
      margin-bottom: 4px;
    }
    .content {
      flex: 1;
    }
  }
}
