.checkoutSign-bodyContainer {
  background-color: #f9f9f9;
  padding-top: 20px;
  padding-bottom: 50px;

  .checkoutSign-contractContainer {
    max-width: 805px;
    margin: auto;

    &__message{
      margin: 0px 20px 30px 20px;
    }

    &__spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 390px);
      overflow: hidden;
    }
  }

  .checkoutSign-contractContainer > p {
    padding: 20px;
  }
  

  .checkoutSign-loader {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkoutSign-innerButtonContainer {
    display: flex;
    margin: 16px;
    justify-content: center;
  }
}
