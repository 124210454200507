.rastreatorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 25px;
  @media (max-width: 768px) {
    gap: 0px;
  }
  .boxInformationContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 92px;
    margin-right: 92px;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 40px 160px 40px 160px;
    gap: 32px;
    max-width: 1440px;

    @media (max-width: 768px) {
      align-items: center;
      margin: auto;
      padding: 32px 24px 40px 24px;
      flex-direction: column;
    }

    &__body {
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: 32px;
      @media (max-width: 768px) {
        align-items: center;
        margin: auto;
        flex-direction: column;
      }
    }

    &__boxInformation {
      max-width: 632px;
      min-width: 632px;
      width: 100%;
      border-radius: 5px;
      padding: 0px 92px 32px 92px;
      @media (max-width: 768px) {
        padding: 0px;
        min-width: 325px;
        max-width: 446px;
      }
    }

    &__form {
      max-width: 446px;
      min-width: 446px;
      width: 100%;
      padding: 24px 24px 32px 24px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1),
        0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.07);
      background-color: var(--theme-quaternary);
      border-radius: 8px;
      @media (max-width: 768px) {
        min-width: 325px;
      }
      &--formC {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
  }

  .headerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .infoContainer {
    width: 100%;
    padding: 80px 135px 80px 135px;
    background-color: var(--theme-quaternary);
    @media (max-width: 768px) {
      padding: 24px;
    }
  }

  .faqContainer {
    width: 100%;
    padding: 90px 135px 80px 135px;
    @media (max-width: 768px) {
      padding: 24px;
    }
  }

  .tableContainer {
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      hr {
        width: 100%;
        border: 1px solid #e2e2e2;
      }
      align-items: center;
      padding: 24px 24px 32px 24px;
      flex-direction: column;
    }
    &__table {
      padding: 24px 48px 32px 48px;
      border-radius: 5px;
      max-width: 632px;
      @media (max-width: 768px) {
        padding: 48px 0px;
        max-width: 446px;
      }
    }

    &__CTA {
      padding: 130px 56px 130px 56px;
      max-width: 446px;
      border-radius: 8px;
      background-color: var(--theme-quaternary);
      @media (max-width: 768px) {
        padding: 50px 0px 50px 0px;
        background-color: white;
      }
    }
  }

  .trustpilotContainer {
    width: 100%;
    @media (max-width: 768px) {
      padding: 0;
      gap: 20px;
    }
  }

  .navbarSticky {
    &__content {
      display: flex;
      flex-direction: column;
      &--crossedOut {
        color: #6f6f6f !important;
      }
    }
  }
}
