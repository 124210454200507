@import '../../../styles/variables';

#customModal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#customModal-container p {
    color: $primary;
}

.customModal-headerContainer {
    background-color: $input-bg-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 2px 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    width: 100%;
    height: 145px;
}

.customModal-headerContainer img {
    padding-right: 10px;
}

.customModal-headerTitle {
    font-weight: 700;
    font-size: 18px;
    color: $primary;
    width: 460px;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 460px;
}

.modal-content-bullets {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    width: calc(100% - 200px);
    padding-bottom: 30px;
}

.modal-content-trustfooter {
    font-weight: 600;
    font-size: 10px;
    padding: 0;
    margin: 0;
    padding-top: 3px;
    color: $text;
}

.modal-content-bullet {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: left;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    width: 100%;
}

.modal-content-bullet img {
    height: 30px;
    padding-right: 10px;
}

.modal-content-title {
    font-weight: 600;
    width: calc(100% - 36px);
}

.customModal-close {
    margin-left: 300px;
    cursor: pointer;
}

.modalButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    min-height: 100px;
    width: 100%;
    background-color: $input-bg-color;
    margin-top: 30px;
}

.modalButtonContainer button {
    margin: 0 10px !important;
    transform: none !important;
    font-size: 16px !important;
    height: 48px !important;
}

.modalButtonContainer .button-secondary {
    background-color: transparent;
    color: $primary;
}

#customModal-container h5 {
    margin: 0 !important;
    padding: 0 !important;
}

.blockers--container {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blockers--container .customModal-close {
    align-self: flex-end;
    margin-right: 1rem;
    margin-top: 1rem;
    color: $primary;
}

.blockers--container .customModal-buttonContainer {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.blockersPopUp-contentExclusion {
    font-size: 20px;
    color: $primary-hover;
}

.blockersPopUp-contentProps {
    font-size: 16px;
    color: $primary-hover;
    padding: 20px;
}

@media only screen and (max-width: 480px) {
    .customModal-headerTitle {
        width: calc(100% - 40px);
    }

    .modal-content {
        width: calc(100% - 40px);
    }

    .modal-content-bullets {
        width: calc(100% - 100px);
    }

    .modalButtonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;
        padding: 20px;
        background-color: $input-bg-color;
        margin-top: 30px;
    }

    .modalButtonContainer button {
        width: 90% !important;
        margin-bottom: 10px !important;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 45;
}

.modal-header {
    display: flex;
    justify-content: end;
    padding: 15px;
}


.modal-header svg {
    cursor: pointer;
}

.customModal-close svg{
    justify-self: flex-end;
    margin: 15px;
}


.modal-main {
    position: fixed;
    background: white;
    width: 60%;
    max-width: 740px;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}