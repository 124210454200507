@import '../../../../../../styles/variables';
.card-form{
    max-width: none;
    display: flex;
    flex-direction: column;

    .cardNumber--input {
        width: 100%;
        border-radius: 12px;
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgb(226 226 226 / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: white;
        padding: 12px 16px;
        font-family: DM Sans;
        font-size: 15px;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        box-shadow: unset;
        max-width: 100%;
    }

    .card--row2{
        display: flex;
        width: 100%;
        justify-content: space-between;
        column-gap: 15px;
        div {
            width: inherit;
        }
    }
}