// Colors

$primary: var(--theme-primary);
$primary-hover: rgba(66, 62, 244, 0.5);
$primary-disabled: rgba(66, 62, 244, 0.5);
$primary-shadow: #f5c4dc;
$label: #2c2302;
$input-border-color: #e7e6df;
$border-hover: #b8b6b0;
$input-bg-color: #f2f2f2;
$text: #4b4b4b;
$shadow: rgba(33, 33, 33, 0.08);
$error: #ff0000;
$pending-color: #fb012e;
$alert-color: #ff9900;
$action-color: #1dbf4a;
$secondary-action-color: #ffd861;
$status-color: #6aa84f;
$white: white;
$bg-card: #ededed;
$radio-hover: rgba(89, 87, 200, 0.3);

$black: black;
$edit-bg: #ebf5ff;
$edit-txt: #339dff;
$cta-bg: #f9eab0;
$warning: #e65437;
$upsell-color: #1bb57c;
$primary-hover: #082624;
$button-hover: #daeddd;
$bg-notcoverage: rgb(245 253 253);
$life-blue-10: #ececfe;

$bg-web: #f9f9f9;
$border: #f3f3f3;

$placeholder: rgb(204, 204, 204);

$inform-bg: #f6f6f6;

// Fonts

$font-family-getlife: "DM sans", sans-serif;

// NEW
$new-primary: var(--theme-primary);
$new-secondary: #f5c4dc;

$font-family-getlife-primary: "DM sans", sans-serif;
$font-family-getlife-secondary: "Merriweather";
// Azul: 423EF4
// Rosa: F5C4DC
// Azul claro: DAF3E9
