@import '../../../../styles/variables';

.nationality-container {
    display: flex;
    flex-direction: column;
}

.nationality-GridContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    input {
        margin-top: 8px;
    }
    .row {
        display: flex;
        flex-direction: row;
        text-align: left;
        gap: 12px;
        div {
            width: 100%;
            max-width: 100%;
        }
    }
    
    button {
        max-width: 100%;
        width: 100%;
    }
}

.nationality-buttonContainer{
    margin: 52px auto 0px auto;
    width: 100%;
    max-width: 360px;
}

.nationality-inputAdd {
    display: flex;
}
.nationality-add {
    margin-top: 20px !important;
    margin-bottom: 20px;
    margin-left: -24px !important;
}

.nationality-container .inputGetLife-errorMessageContainer {
    padding-top: 0px;
    max-height: 10px;
}

.nationality-container p.inputGetLife-errorMessage {
    position: relative;
    top: 5px;
    padding: 0px !important;
    margin: 0px !important;
    color: $error;
}

@media only screen and (max-width: 720px) {
    .nationality-container {
        padding-left: 0px;
        width: 100%;
    }

    .nationality-buttonContainer {
        width: 100%;
        padding: 0px 20px 0px 20px;
        margin: 0px;
        margin-top: 52px ;
    }

    .nationality-GridContainer {
        .row {
            display: flex;
            flex-direction: column;
            text-align: left;
        }
    }
}