@import "../../../styles/variables";

.questions-outerContainer {
  background-color: $white;
  margin: 0 !important;
  min-height: 100%;
  min-width: 100%;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  &.prestack {
    height: calc(100vh - 75px);
  }
}

.questions-downgraded-dialog-close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0 !important;
}

.questions-downgraded-dialog-content {
  padding: 20px !important;
  background-color: $white;
}

.questions-downgraded-dialog-content p {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
}

.questions-downgraded-dialog-content span {
  font-size: 20px;
  font-weight: 700;
  color: $primary-disabled;
}

.questions-container {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.questions-innerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.questions-innerContainer .control {
  max-height: unset;
  min-height: unset;
}

.questions-innerContainer .MuiFormControl-root {
  max-height: unset;
  min-height: unset;
}

.inBottomButtons {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 40;
  height: 100px;
  flex-direction: row-reverse;
  gap: 16px;
  padding-right: 24px;
  padding-left: 24px;
  justify-content: center;
  align-items: center;
}

.inBottomButtons .formBuilder-buttonContainer {
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .questions-container {
    width: 100%;
  }

  .questions-innerContainer {
    padding: 40px 24px;
    &-it {
      padding: 24px;
    }
  }

  .mobileShow {
    display: flex;
    margin-bottom: auto;
    flex-direction: row-reverse;
    gap: 16px;
  }

  .questions-container button {
    width: 100% !important;
  }

  .questions-container .location-search-input {
    width: calc(100% - 15px);
  }
}

/* Smartphone Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .mobileShow {
    display: flex;
    width: 100%;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    background-color: white;
    z-index: 40;
    height: 100px;
    box-shadow: 4px 0px 25px rgba(0, 0, 0, 0.15);
    flex-direction: row-reverse;
    gap: 16px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .footerContainerMobile {
    padding-bottom: 100px;
  }

  .mobileConditionsStyle {
    position: absolute;
    bottom: 24px;
    left: 24px;
    right: 24px;
    display: flex;
  }
}

.buttonsContainer {
  margin-bottom: auto;
  width: fit-content;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 32px;
}

.displayContents {
  display: contents;
}

.conditionsModalPromoText {
  color: var(--theme-primary);
  font-weight: 700;
}
